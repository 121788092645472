<template>
  <AppHeader />
  <Suspense>
    <template #default>
       <Arts />
    </template>
    <template #fallback>
       <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
const Arts = defineAsyncComponent(() =>
  import('@/components/Categorys/Arts.vue')
)
export default {
   name: 'Art',
   components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    Arts
  },
}
</script>

<style>

</style>